import { ApolloClient } from "@apollo/client";
import { SubscriptionLineFragmentFragment } from "@smartrr/shared/shopifyGraphQL/api";
import {
  getShopifySubscriptionContractDiscounts,
  queryShopifySubscriptionContract,
  queryShopifySubscriptionContractLineItems,
} from "@smartrr/shared/shopifyGraphQL/subscriptionContracts";

export const getRawContractData = async (shopifyId: string, apolloClient: ApolloClient<object>) => {
  const res = await queryShopifySubscriptionContract(shopifyId, apolloClient);
  if (res.type === "error") {
    throw new Error(`Couldn't get shopify data for contract ${shopifyId}`);
  }
  const subscriptionContractDiscountsPromise = getShopifySubscriptionContractDiscounts(shopifyId, apolloClient);

  const subscriptionLineItemsPromise = queryShopifySubscriptionContractLineItems(
    shopifyId,
    res.body.data.subscriptionContract!.lineCount,
    apolloClient
  );
  const [linesRes, discounts] = await Promise.all([
    subscriptionLineItemsPromise,
    subscriptionContractDiscountsPromise,
  ]);

  let lines: SubscriptionLineFragmentFragment[] = [];
  if (linesRes.type === "success") {
    lines = linesRes.body.data.subscriptionContract!.lines.edges.map(({ node }) => node);
  }

  return { lines, discounts, contract: res.body.data.subscriptionContract! };
};
